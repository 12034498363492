import { memo, useEffect, useMemo } from 'react';
import {
	Link,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { useAtom } from 'jotai/index';
import { Property } from '../../models/property.model.ts';
import { propertiesAtom } from '../../atoms/properties.atom.ts';
import Map from '../../components/map/map.tsx';
import Popover from '../../components/popover/popover.tsx';

const ReservationDetails = memo(function () {
	const navigate = useNavigate();
	const { propId } = useParams();
	const [properties] = useAtom<Property[]>(propertiesAtom);

	const property = useMemo(
		() => properties.find(prop => prop.id === propId),
		[propId, properties],
	);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'instant' });
	}, []);

	return (
		<div className='reservation-details p-8'>
			<div className='header position-fixed full-width p-16 justify-content-between d-flex align-items-center'>
				<div>
					<button
						className='btn-link d-flex align-items-center text-decoration-none'
						onClick={() => navigate(-1)}>
						<i className='icon-arrow-right fs-30 text-secondary'></i>
					</button>
				</div>
				<div className='d-flex justify-self-center transform-center'>
					<h1 className='text-secondary m-0 fs-22'>تفاصيل الحجز</h1>
				</div>
			</div>

			<div className=' p-8 mt-50'>
				<h4 className='text-secondary fs-15'>تفاصيل العقار</h4>
				<div className='sec1 d-flex justify-content-between align-items-center'>
					<div className='img-details'>
						<img
							src={property?.images[0]}
							className='full-width full-height'
							alt='post-image'
						/>
					</div>
					<div className='sec1-details'>
						<h3 className='text-secondary  fs-12'>{property?.title}</h3>
						<div className='post__features  mt-12 mb-5'>
							<div className='post__features__feature'>
								<i className='icon-overall-reduction me-4'></i>
								{property?.size} م2
							</div>
							<div className='post__features__feature'>
								<i className='icon-single-bed me-4'></i>
								{property?.rooms} غرف
							</div>
							<div className='post__features__feature'>
								<i className='icon-shower-head me-4'></i>
								{property?.bathrooms} دورات مياه
							</div>
						</div>
						<div className='post__values mx-12'>
							<div className='post__values__prices'>
								<h4 className='text-secondary'>
									{property?.discount
										? property?.price - property?.price * property?.discount
										: property?.price}
								</h4>
								{property?.discount !== 0 && (
									<h4 className='text-red line-through'>{property?.price}</h4>
								)}
								<p className='text-secondary fs-12'>ريال</p>
							</div>
						</div>
					</div>
				</div>

				<div>
					<div>
						<h4 className='text-secondary fs-15'>تفاصل الموقع</h4>
					</div>
				</div>
				<div className='location p-10'>
					{property?.location_coords && (
						<Map
							lat={Number(property?.location_coords?.split(',')[0])}
							lng={Number(property?.location_coords?.split(',')[0])}
						/>
					)}
					<p className='text-secondary fs-12 text-bold'>
						مدينة {property?.city.name}، حي {property?.district.name}، شارع{' '}
						{property?.street_name}
					</p>
				</div>

				<Popover text='العربون هو مبلغ مالي يدفعه المستأجر كتأكيد جدية الحجز. يتم استخدام العربون لضمان تأكيد الحجز وحجز الوحدة السكنية المختارة لكم. في حالة تأكيد الحجز واستكمال الإجراءات، يُخصم مبلغ العربون من الإجمالي المستحق للحجز. يتم تطبيق سياسة الإلغاء على مبلغ العربون وفقًا للشروط المحددة في سياسة الحجز.'>
					<div className='d-flex  align-items-center mt-20 mb-10'>
						<h4 className='text-secondary fs-15  m-0'>العربون</h4>
						<i className='info icon-info fs-18 p-6 me-2 '></i>
					</div>
				</Popover>
				<div className='sec2 p-8'>
					<p className=' arbon fs-15 '>
						يُطلب دفع عربون بقيمة 1000 ريال لتأكيد الحجز. العربون يضمن جدية
						الحجز وسيُخصم من الإجمالي المستحق للحجز عند استكمال العملية.
					</p>
				</div>

				<div>
					<div>
						<h4 className='text-secondary fs-20 mb-2 '>طريقةالدفع</h4>
						<h5 className=' sec3 fs-15 m-2'>اختر طريقة الدفع المفضلة لديك</h5>
					</div>

					<div className=' sec4 d-flex justify-content-between align-items-center p-8 mt-10'>
						<div className='d-flex justify-content-between align-items-center p-8 '>
							<div>
								<form>
									<input
										className='fs-30 w-15 h-15'
										type='radio'
										name='payment'
										value=''
										checked
									/>
								</form>
							</div>
							<div>
								<h4 className='head1 fs-15 mt-10 mb-2'>
									ميسر, ماستركارد, فيزا
								</h4>
								<h5 className=' head2 fs-12 mt-2'>
									ادفع بالطريقة اللي تناسبك، بدون فوائد أو رسوم خفية!
								</h5>
							</div>
						</div>

						<div className='d-flex'>
							<img
								src='/images/mada.png'
								className='w-20 object-fit-contain'
								alt='post-image'
							/>
							<img src='/images/visa.png' className=' ' alt='post-image' />
							<img
								src='/images/mastercard.png'
								className=' '
								alt='post-image'
							/>
						</div>
					</div>
				</div>

				<div className='mt-20'>
					<Link
						className='button-pay full-width text-bold text-decoration-none d-flex justify-content-center align-items-center text-decoration-none'
						to={`/payment/${propId}`}>
						الدفع الآن 1000 ريال
					</Link>
				</div>
			</div>
		</div>
	);
});

export default ReservationDetails;
