import { memo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAtom } from 'jotai';
import {
	FilterActionsType,
	propertyFilterAtom,
} from '../../atoms/properties-filter.atom.ts';
import SearchBar from '../../components/search-bar/search-bar.tsx';
import Post from '../../components/post/post.tsx';
import Chat from '../../components/chat/chat.tsx';
import {
	getPropertyFilterValues,
	getPropertyList,
} from '../../services/property.service.ts';
import { PropertiesRes } from '../../models/property.model.ts';
import { propertiesAtom } from '../../atoms/properties.atom.ts';
import { propertiesFilterValuesAtom } from '../../atoms/properties-filter-values.atom.ts';
import { useNavigate } from 'react-router-dom';

const HomePage = memo(function () {
	const [properties] = useAtom(propertiesAtom);
	const [filter, filterDispatcher] = useAtom(propertyFilterAtom);
	const [_, setFilterValues] = useAtom(propertiesFilterValuesAtom);
	const navigate = useNavigate();

	const { data } = useQuery<PropertiesRes>({
		queryKey: ['properties-list', filter],
		queryFn: () => getPropertyList(filter),
	});

	useEffect(() => {
		const url = new URL(window.location.href);
		const showInvoice = url.searchParams.get('showInvoice');
		if (showInvoice) {
			navigate(`details/${showInvoice}`);
		}

		getPropertyFilterValues().then(setFilterValues);
	}, []);

	const loadNextPage = () => {
		filterDispatcher({ type: FilterActionsType.NEXT_PAGE });
	};

	const refresh = () => {
		filterDispatcher({ type: FilterActionsType.REFRESH });
	};

	return (
		<div className='home-page'>
			<div className='header position-fixed full-width'>
				<div className='d-flex justify-content-center align-items-center'>
					<h4 className='text-primary mb-0 mt-15 fs-22'>
						AQARX <i className='icon-logo text-primary ms-10 fs-26'></i>
					</h4>
				</div>
				<SearchBar />
			</div>

			<div className='content px-20'>
				<h3 className='text-secondary mt-25 mb-0'>الوحدات السكنية المتاحة</h3>
				<InfiniteScroll
					next={loadNextPage}
					hasMore={filter.page !== data?.properties?.last_page}
					loader={
						<div className='text-center mt-150'>
							<CircularProgress />
						</div>
					}
					pullDownToRefresh
					refreshFunction={refresh}
					dataLength={properties.length}>
					{properties.map(property => (
						<Post key={property.id} property={property} />
					))}
				</InfiniteScroll>
			</div>
			<Chat />
			<div className='footer d-flex full-width position-fixed align-items-center justify-content-between px-10'>
				<div className='footer__social-media d-flex gap-5'>
					<a href='javascript:void(0)'>
						<i className='icon-facebook text-secondary'></i>
					</a>
					<a href='javascript:void(0)'>
						<i className='icon-instagram text-secondary'></i>
					</a>
					<a href='javascript:void(0)'>
						<i className='icon-twitter text-secondary'></i>
					</a>
					<a href='javascript:void(0)'>
						<i className='icon-youtube text-secondary'></i>
					</a>
				</div>
				<div className='footer__copywright'>
					<p className='fs-10'>جميع حقوق النشر محفوظة لدى © 2024 AQARX Ltd</p>
				</div>
				<div className='footer__logo d-flex gap-5 text-primary'>
					<h5 className='m-0'>AQARX</h5>
					<i className='icon-logo'></i>
				</div>
			</div>
		</div>
	);
});

export default HomePage;
