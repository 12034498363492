import { memo, useEffect } from 'react';
import { moyasarAPIPublicKey } from '../../config/config.ts';
import { useParams } from 'react-router-dom';

declare const Moyasar: any;

const Payment = memo(function() {
	const { propId } = useParams();

	useEffect(() => {
		Moyasar.init({
			element: '.payment',
			amount: 100000,
			currency: 'SAR',
			description: 'شراء عقار جديد',
			language: 'ar',
			publishable_api_key: moyasarAPIPublicKey,
			methods: ['creditcard'],
			fixed_width: false,
			callback_url: `${window.location.origin}/?showInvoice=${propId}`,
			on_failure: () =>
				new Promise((_, reject) => {
					reject('Payment Rejected');
				})
		});
	}, []);

	return (
		<>
			<div className="header position-fixed full-width">
				<div className="d-flex justify-content-center align-items-center">
					<h4 className="text-primary mb-0 my-15 fs-22">
						AQARX <i className="icon-logo text-primary ms-10 fs-26"></i>
					</h4>
				</div>
			</div>
			<div className="payment"></div>
		</>
	);
});

export default Payment;
