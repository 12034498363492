import { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function Typewriter(props: {
	text: string;
	speed?: number;
	onDone?: () => void;
}) {
	const { text, speed = 100, onDone } = props;

	const [displayText, setDisplayText] = useState('');
	const initialized = useRef<boolean | null>(false);
	const currentIndexRef = useRef(0);
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);

	useEffect(() => {
		if (!initialized.current) {
			initialized.current = true;

			currentIndexRef.current = 0;

			const typeNextCharacter = () => {
				if (currentIndexRef.current < text.length) {
					setDisplayText(text.slice(0, currentIndexRef.current + 1));
					currentIndexRef.current += 1;

					// @ts-ignore
					timeoutRef.current = setTimeout(typeNextCharacter, speed);
				}
			};

			// Start the typing effect
			// @ts-ignore
			timeoutRef.current = setTimeout(typeNextCharacter, speed);

			typeNextCharacter();
		}

		// Cleanup function
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, [text, speed]);

	useEffect(() => {
		if (onDone && displayText === text) {
			onDone();
		}
	}, [onDone, displayText]);

	return (
		<Box>
			<Markdown remarkPlugins={[remarkGfm]}>{displayText}</Markdown>
		</Box>
	);
}

export default Typewriter;
